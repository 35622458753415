import React from "react"
import { Link } from "react-scroll";
import { Container, Navbar, Nav, Image } from "react-bootstrap"
import { Contract } from "web3-eth-contract"
import { FaInstagram, FaDiscord, FaTwitter } from 'react-icons/fa';
import OpenseaLogo from "../../images/OpenseaLogo.png"
import LCCCLogo from "../../images/LCCCLogo.png"

const styles = require("./navBar.module.scss")

type NavBarProps = {
  pageInfo: {
    curPage: string,
  },
  accountAddress: string | null,
  contract: Contract,
  getAndSetAccount: Function,
}

const PageNavbar: React.FC<NavBarProps> = ({ pageInfo = { curPage: "/" }, accountAddress, contract, getAndSetAccount }) => {
  return (
    <>
      <Navbar collapseOnSelect variant="light" expand="lg" fixed="top" className={styles.navbar}>
        <Container>
          <Navbar.Brand>
            <Link
              to={"welcome"}
              smooth={true}
              offset={-80}
              duration={100}
              spy={true}
            >
              <Image className={styles.brandImage} src={LCCCLogo} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className={`d-flex flex-column align-items-end justify-content-end ${styles.navContentContainer}`}>
              <Nav className={`d-flex align-items-center justify-content-center flex-row ${styles.socialIcons}`}>
                <a href="https://opensea.io/collection/lightcultcryptoclub" target="_blank">
                  <Image
                    src={OpenseaLogo}
                    className={`${styles.navSocialIcon} ${styles.openseaLogo}`}
                  />
                </a>
                <a href="https://discord.gg/F6vy9vsAQe" target="_blank">
                  <FaDiscord className={styles.navSocialIcon} color={"black"} size={25} />
                </a>
                <a href="https://www.instagram.com/lightcultcryptoclub/" target="_blank">
                  <FaInstagram className={styles.navSocialIcon} color={"black"} size={25} />
                </a>
                <a href="https://twitter.com/LightCultCrypto" target="_blank">
                  <FaTwitter className={`${styles.navSocialIcon} ${styles.lastNavSocialIcon}`} color={"black"} size={25}/>
                </a>
              </Nav>
              <Nav className="d-flex align-items-center justify-content-center flex-wrap">
                <Link
                  className={styles.navLink}
                  activeClass={styles.activeNavLink}
                  to={"welcome"}
                  smooth={true}
                  offset={-80}
                  duration={100}
                  spy={true}
                >
                  WELCOME
                </Link>
                {/* <Link
                  className={styles.navLink}
                  activeClass={styles.activeNavLink}
                  to={"burn"}
                  smooth={true}
                  offset={-80}
                  duration={100}
                  spy={true}
                >
                  BURN
                </Link> */}
                <Link
                  className={styles.navLink}
                  activeClass={styles.activeNavLink}
                  to={"about"}
                  smooth={true}
                  offset={-80}
                  duration={100}
                  spy={true}
                >
                  ABOUT
                </Link>
                <Link
                  className={styles.navLink}
                  activeClass={styles.activeNavLink}
                  to={"roadmap"}
                  smooth={true}
                  offset={-80}
                  duration={100}
                  spy={true}
                >
                  ROADMAP
                </Link>
                <Link
                  className={styles.navLink}
                  activeClass={styles.activeNavLink}
                  to={"team"}
                  smooth={true}
                  offset={-80}
                  duration={100}
                  spy={true}
                >
                  TEAM
                </Link>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default PageNavbar
